.makers-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.makers-bg-primary {
    background-color: $main-color;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: url('../../assets/bg/bg-arrow-2.svg') no-repeat 0% 100%;
      width: 100%;
      height: 100%;
    }
  }
}
