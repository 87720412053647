// These variables will be passed to bootstrap.
// Any variables that are also used in bootstap will effectively
// overwrites bootstrap default values.
// For reference, bootstap complete variables list should be available
// here: "node_modules/bootstrap/scss/_variables.scss"
//
// NOTE:
// gradient is background-image not a color, substituting a color
// for gradient will result in error
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Montserrat');

$font-family-sans-serif: "Noto Sans Display", sans-serif !default;
$font-family-monospace: "Fira Code", monospace !default;
$font-family-helvetica: 'Poppins', sans-serif !default;
$font-family-montserrat: 'Montserrat', sans-serif !default;
$font-family-merriweather: 'Merriweather Sans', sans-serif;
$font-family-rubik: 'Rubik', sans-serif !default;

$bg-dark: #252b31 !default;
$bg-light: #fafcff !default;
$text-dark: #545455 !default;

$btn-hover-color: #4aaad6 !default;
$btn-active-color: #257599 !default;
$btn-disable-color: #76b5d2 !default;

$enable-gradients: true;

$main-color: #F7DC28 !default;

$primary: $main-color;
$primary-hover: #CFB717;
$primary-surface: #FFFDEB;
$primary-border: #F5E576;
$primary-pressed: #F5E576;

$secondary: #ED3237;
$secondary-hover: #C71C21;
$secondary-surface: #FDE9EA;
$secondary-border: #FF9295;
$secondary-pressed: #790709;

$success: #2AB037;

$danger: #e27363;

$warning: #fdd46c;

$info: #4555E8;

$neutral-10: #FFFFFF;
$neutral-20: #F7F7F7;
$neutral-30: #EDEDED;
$neutral-40: #E0E0E0;
$neutral-50: #C2C2C2;
$neutral-60: #9E9E9E;
$neutral-70: #757575;
$neutral-80: #616161;
$neutral-90: #404040;
$neutral-100: #0A0A0A;

$colors: (
  "neutral-10": $neutral-10,
  "neutral-20": $neutral-20,
  "neutral-30": $neutral-30,
  "neutral-40": $neutral-40,
  "neutral-50": $neutral-50,
  "neutral-60": $neutral-60,
  "neutral-70": $neutral-70,
  "neutral-80": $neutral-80,
  "neutral-90": $neutral-90,
  "neutral-100": $neutral-100
);

$line-clamps: 1, 2, 3, 4, 5, 6, 7, 100 ;