.react-dataTable .rdt_TableBody .rdt_TableRow {
	.rdt_TableCell {
		margin-bottom: 1rem !important;
		min-height: 60px !important;
		padding: auto !important;

		h6,
		a {
			color: $primary;

			&:hover {
				color: $info;
			}
		}

		//Badge
		.badge {
			min-width: 70px !important;
			padding: 0.7rem !important;
			border-radius: 0.558rem !important;
			font-weight: 300 !important;
			margin: 0.4rem 0.2rem !important;

			&.badge-light-secondary a {
				color: #82868b !important;

				&:hover {
					color: #000 !important;
				}
			}
		}
	}
}
