#karir-page {
  position: relative;
  padding: 2.5rem calc(3rem + 6.111vw) calc(2rem + 1.806vw);

  .makers-bg {
    margin-top: 4.813rem;
  }

  .filter-card {
    margin-top: -40px;
    border: none;
    box-shadow: none;
    margin-bottom: 2.5rem;
    border-radius: 0px !important;

    .card-body {
      padding: 1rem 0.719rem;

      .row {
        .col:first-of-type {
          flex: 0 0 auto;
          width: 39.25%;
          ;
        }
      }

      .form-control,
      .form-select {
        padding: 0.75rem 1rem;
        font-family: $font-family-rubik !important;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.313;

        letter-spacing: 0.0115em;

        background-color: $neutral-10 !important;
        border: 1px solid $neutral-30 !important;
        border-radius: 8px;
      }

      .input-group {
        .input-group-text {
          border: 1px solid $neutral-30;
          border-radius: 8px;
        }

        >:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          padding-right: 5px;
        }

        >:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 !important;
          padding-left: 5px;
        }
      }
    }
  }

  .karir-tabs {
    padding: 0 1rem;

    .nav-item {
      margin-right: 0.5rem;

      .nav-link {
        color: $neutral-60;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;

        &:not(.active):hover {
          background-color: #00000010 !important;
        }

        &.active {
          color: $neutral-90;
          background-color: $neutral-10;
          border-bottom: 4px solid $secondary !important;

          &:hover {
            background-color: $neutral-10 !important;
          }

          svg {

            circle,
            polygon,
            path {
              stroke: $primary-hover;
            }

            circle,
            path {
              fill: $primary;
            }

            polygon {
              fill: $primary-hover;
            }
          }
        }
      }
    }
  }

  .karir-main {
    max-height: calc(18.75rem + 23.958vw);

    > div {
      max-height: inherit;
    }

    .karir-bookmark {
      cursor: pointer;

      &.filled {
        path {
          fill: $primary;
          stroke: $primary-hover;
        }
      }
    }

    .karir-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: auto;
      max-height: inherit;
      padding-right: 0.75rem;

      .karir-item {
        padding: 0.875rem 1rem;
        border: 1px solid $neutral-30;
        border-radius: 8px;
        background-color: $neutral-10 !important;

        .karir-item-header {
          img {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            object-fit: contain;
          }
        }

        &:hover {
          border: 1px solid $neutral-60;
        }

        &.active {
          border: 1px solid $info !important;
        }
      }

      &::-webkit-scrollbar {
        width: 15px;
        height: 67px;
      }

      &::-webkit-scrollbar-track {
        background: $neutral-20;
        border-radius: 74px;
      }

      &::-webkit-scrollbar-thumb {
        background: $neutral-60;
        border-radius: 74px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $neutral-70;
      }

      &::-webkit-scrollbar-thumb:active {
        background: $neutral-80;
      }
    }

    .karir-detail {
      padding: 1.5rem;
      border: 1px solid $neutral-30;
      border-radius: 8px;

      .karir-detail-header {
        img {
          height: 64px;
          width: 64px;
          border-radius: 50%;
          object-fit: contain;
        }

        svg {
          cursor: pointer;
        }
      }

      .karir-detail-body {
        overflow-y: auto !important;
        max-height: 510px !important;

        padding-right: 15px;

        button {
          border-radius: 8px;
        }

        &::-webkit-scrollbar {
          width: 15px;
          height: 67px;
        }
  
        &::-webkit-scrollbar-track {
          background: $neutral-20;
          border-radius: 74px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: $neutral-60;
          border-radius: 74px;
        }
  
        &::-webkit-scrollbar-thumb:hover {
          background: $neutral-70;
        }
  
        &::-webkit-scrollbar-thumb:active {
          background: $neutral-80;
        }
      }

      .karir-detail-content {
        * {
          letter-spacing: 0 !important;
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .makers-bg {
    margin-top: 0px;
  }

  .filter-card {
    margin-top: 47px;
    border: none;
    box-shadow: none;
    margin-bottom: 0px !important;
    border-radius: 0px !important;

    .card-body {
      padding: 1rem 0.719rem;

      .row {
        .col:first-of-type {
          flex: 0 0 auto;
          width: 35%;
          ;
        }
      }

      .form-control,
      .form-select {
        padding: 0.75rem 1rem;
        font-family: $font-family-rubik !important;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.313;

        letter-spacing: 0.0115em;

        background-color: $neutral-10 !important;
        border: 1px solid $neutral-30 !important;
        border-radius: 8px;
      }

      .input-group {
        .input-group-text {
          border: 1px solid $neutral-30;
          border-radius: 8px;
        }

        >:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          padding-right: 5px;
        }

        >:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0 !important;
          padding-left: 5px;
        }
      }
    }
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .makers-bg {
    margin-top: 0px !important;
  }

  .filter-card {
    margin-top: 0px !important;
    border: none;
    box-shadow: none;
    margin-bottom: 2.5rem;
    border-radius: 0px !important;

    .card-body {
      padding: 0px;

      .row {
        gap: 1rem;

        .col:first-of-type {
          flex: 0 0 auto;
          width: 100%;
          ;
        }

        .col:last-of-type {
          button {
            width: 100%;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  #karir-page {
    padding: 0;

    .makers-bg {
      margin-top: 8.813rem;
    }

    .filter-card {
      margin-top: 0px !important;
      border: none;
      box-shadow: none;
      margin-bottom: 2.5rem;
      border-radius: 0px !important;

      .card-body {
        padding: 0px;
  
        .row {
          gap: 1rem;

          .col:first-of-type {
            flex: 0 0 auto;
            width: 100%;
            ;
          }

          .col:last-of-type {
            button {
              width: 100%;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .karir-tabs {
      display: none !important;
    }
  
    .card {
      border-radius: 0px !important;
      padding: 16px;
  
      .karir-main {
        margin-top: 24px !important;
    
        .karir-list {
          .karir-item {
            &.active {
              border: 1px solid $neutral-30 !important;
            }
          }
  
          &::-webkit-scrollbar {
            width: 8px !important;
            height: 111px !important;
          }
        }
      }
    }
  }
}

#karir-detail-page {
  @media (min-width: 426px) and (max-width: 768px) {
    .navigation {
      padding-block: 26px;
      
      svg {
        width: 20px;
        height: 20px;
        margin-right: 15.5px;
      }
    }

    padding-inline: 50px;
    .karir-detail-header {
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        object-fit: contain;
      }
  
      svg {
        cursor: pointer;
      }
    }
  
    .karir-detail-body {
      .btn {
        width: 100%;
        border-radius: 8px;
      }
    }
  }

  @media (max-width: 425px){
    padding-inline: 24px;

    .navigation {
      padding-block: 26px;
      
      svg {
        width: 20px;
        height: 20px;
        margin-right: 15.5px;
      }
    }

    .karir-detail-header {
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        object-fit: contain;
      }
  
      svg {
        cursor: pointer;
      }

      .text-subheading-1-bold {
        font-size: 16px;
        line-height: 24px;
      }

      .text-body-1-regular {
        font-size: 12px;
        line-height: 18px;
      }
    }
  
    .karir-detail-body {
      .text-body-2-regular {
        font-size: 12px;
        line-height: 18px;
      }

      .btn {
        width: 100%;
        border-radius: 8px;
      }
    }
  
    .karir-detail-content {
      font-size: 12px;
      line-height: 18px;
    }
  }
}