@use "sass:math";

// Modals

.modal {
  // Modal Header

  .modal-header {
    background-color: $dark;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjNzM2N2YwIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4NDdhZWYiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4K");
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: .5rem 1rem;
    align-items: center;

    // close button
    .btn-close {
      transition: all 0.23s ease 0.1s;
      position: relative;

      color: $light;
      text-shadow: 0 0 1px $dark;
      width: auto;
      height: auto;
      margin-right: 0;

      // For hover effect of close btn
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        outline: none;
      }
    }
  }

  .modal-title {
    color: $light;
    font-size: 1rem;
    line-height: revert;
  }

  // Modal Content
  .modal-content {
    border: none;
    // overflow: unset; updated to "visible" as close icon is not visible in IE
    overflow: visible;
    box-shadow: 0 5px 20px 0 rgba($black, 0.1);
  }

  .modal-body {
    // padding: $modal-inner-padding;
    padding: 1rem;

    button, label.btn {
      display: flex;
      align-items: center;
      margin: 0;
      padding: .5rem 1rem;

      &> svg {
        margin-right: .125rem;
      }
    }

    &> .alert:first-child {
      margin: -1rem;
      border-radius: 0;

      .btn-close {
        top: .5rem;
      }
    }
  }

  .modal-footer {
    // padding: $modal-inner-padding;
    padding: .75rem 1rem;

    &> button {
      display: flex;
      align-items: center;
      margin: 0;
      padding: .5rem 1rem;

      &> svg {
        margin-right: .125rem;
      }
    }
  }

  .modal-alert {
    margin: -1rem;
  }

  // modal sticky for apps
  &.modal-sticky {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    height: auto;
    position: fixed;
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .modal-dialog {
      margin-right: #{$content-padding} !important;
      box-shadow: 0px 0px 24px 0 rgba($black, 0.25);
      border-radius: $border-radius;
    }
    .modal-actions {
      background: transparent;
      box-shadow: none;
      line-height: 0;
    }
  }
}

// Modal XS
.modal-xs {
  max-width: 300px;
}

// Modal XL
.modal-xl {
  margin-left: 3%;
  margin-right: 3%;
}

// Slide In Modal //
.modal-slide-in,
.modal-slide-in .modal {
  padding: 0 !important;
  overflow: hidden !important;
}

.modal-slide-in {
  .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    margin: 0;
    max-width: none;
    width: ($modal-slide-in-width-sm - 5);

    .modal-content {
      padding-bottom: ($modal-title-line-height * $btn-close-width) + $modal-header-padding-y;
      padding-top: ($modal-title-line-height * $btn-close-width) + $modal-header-padding-y;
      overflow: auto;
      border-radius: 0;
      height: 100%;
    }

    .modal-body {
      padding-bottom: 0;
      padding-top: 0;
      margin: auto 0;
      flex-grow: 0;
    }

    @media (min-width: (map-get($grid-breakpoints, sm))) {
      width: $modal-slide-in-width;
    }

    &.sidebar-sm {
      width: $modal-slide-in-width-sm;
    }
    &.sidebar-lg {
      @media (min-width: (map-get($grid-breakpoints, sm))) {
        width: $modal-slide-in-width-lg;
      }
    }
  }

  .close {
    top: math.div($modal-header-padding-y, 2);
    z-index: 10;
    transform: none;
    position: absolute;
    top: 1.5rem;
    right: $modal-header-padding-x;
    margin: 0;
    padding: 0 !important;
    line-height: 0.5;
    transform: translate(0, -50%);
  }
}

// Slide from Right
.modal-slide-in.fade .modal-dialog,
.modal-slide-in .modal.fade .modal-dialog {
  transform: translateX(100%);
  transition: transform 0.1s ease-out;
}

.modal-slide-in.show .modal-dialog,
.modal-slide-in .modal.show .modal-dialog {
  transform: translateX(0) !important;
  transition: transform 0.1s ease-out;
}

// To remove Max-width of XS Modal in Small Screen

@media (max-width: 576px) {
  .modal {
    padding-right: 1rem;
    padding-left: 1rem;
    .modal-xs,
    .modal-sm {
      max-width: unset;
    }
    &.modal-sticky .modal-dialog {
      margin-right: 0.2rem !important;
      margin-left: 0.2rem;
      margin-bottom: 3rem;
    }
  }
}
