.text-heading-1-bold,
.text-heading-1-regular,
.text-heading-2-bold,
.text-heading-2-regular,
.text-heading-3-bold,
.text-heading-3-regular,
.text-subheading-1-bold,
.text-subheading-1-regular,
.text-subheading-2-bold,
.text-subheading-2-regular {
  font-family: $font-family-merriweather !important;
}

.text-heading-1-bold,
.text-heading-2-bold,
.text-heading-3-bold,
.text-subheading-1-bold,
.text-subheading-2-bold {
  font-weight: 700;
}

.text-heading-1-regular,
.text-heading-2-regular,
.text-heading-3-regular,
.text-subheading-1-regular,
.text-subheading-2-regular {
  font-weight: 400;
}

.text-heading-1-bold,
.text-heading-1-regular {
  font-size: 48px;
  line-height: 72px;
}

.text-heading-2-bold,
.text-heading-2-regular {
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.015em;
}

.text-heading-3-bold,
.text-heading-3-regular {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.0025em;
}

.text-subheading-1-bold,
.text-subheading-1-regular {
  font-size: 24px;
  line-height: 36px;
}

.text-subheading-2-bold,
.text-subheading-2-regular {
  font-size: 20px;
  line-height: 36px;
}

.text-body-1-bold,
.text-body-1-medium,
.text-body-1-regular,
.text-body-2-bold,
.text-body-2-medium,
.text-body-2-regular,
.text-body-3-bold,
.text-body-3-medium,
.text-body-3-regular,
.text-caption-bold,
.text-caption-medium,
.text-caption-regular {
  font-family: $font-family-rubik !important;
  letter-spacing: 0.0115em !important;
}

.text-body-1-bold,
.text-body-2-bold,
.text-body-3-bold,
.text-caption-bold {
  font-weight: 700;
}

.text-body-1-medium,
.text-body-2-medium,
.text-body-3-medium,
.text-caption-medium {
  font-weight: 500;
}

.text-body-1-regular,
.text-body-2-regular,
.text-body-3-regular,
.text-caption-regular {
  font-weight: 400;
}

.text-body-1-bold,
.text-body-1-medium,
.text-body-1-regular {
  font-size: 16px;
  line-height: 24px;
}

.text-body-2-bold,
.text-body-2-medium,
.text-body-2-regular {
  font-size: 14px;
  line-height: 21px;
}

.text-body-3-bold,
.text-body-3-medium,
.text-body-3-regular {
  font-size: 12px;
  line-height: 18px;
}

.text-caption-bold,
.text-caption-medium,
.text-caption-regular {
  font-size: 10px;
  line-height: 18px;
}
