.custom-nav-buttons.nav {
  gap: 27px;

  .nav-item {
    .nav-link {
      padding: 12px;
      border: 1px solid $neutral-50;
      border-radius: 4px;

      font-weight: 700;
      font-size: calc(0.875rem + 0.278vw);
      line-height: 1.375rem;
      color: $neutral-50;

      &:hover {
        background-color: #9E9E9E10;
      }

      &.active {
        background-color: $secondary;
        color: white;
        border: 1px solid $secondary;

        &:hover {
          background-color: $secondary !important;
        }
      }
    }
  }

  &.alt-1 {
    .nav-item {
      .nav-link {
        border-radius: 0;
        border: 1px solid $danger;

        &:not(.active) {
          color: $neutral-90;
        }
      }
    }
  }

  &.full {
    .nav-item {
      flex: auto;

      .nav-link {
        text-align: center;
      }
    }
  }
}
