#kolaborasi-page {
  position: relative;
  padding: 2.5rem calc(3rem + 6.181vw) calc(2rem + 1.389vw);
  background-color: #FAFAFA;
  border-bottom: 1px solid #E0E0E0;

  .card {
    border: 1px solid $neutral-30;
    border-radius: 1rem !important;
    box-shadow: none;

    .kolaborasi-header {
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    .kolaborasi-detail {
      margin-top: 4rem;

      .kolaborasi-highlight {
        margin-block: 2.875rem;
        display: flex;
        align-items: start;

        img {
          // min-width: calc(16rem + 10.972vw);
          // max-width: calc(16rem + 10.972vw);
          // height: calc(9rem + 10.764vw);
          // background-color: #D9D9D9;
          width: 100%;
          height: auto;
        }
      }

      .card {
        border-radius: 0.5rem !important;
      }

      .form-control {
        background-color: $neutral-10 !important;
        border: 1px solid $neutral-40 !important;
        border-radius: 8px;
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.313rem;

        letter-spacing: 0.0115em;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 2.5rem 1.111vw calc(1rem + 1.389vw);

    .card {
      .kolaborasi-header {
        .text-heading-2-bold {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
        }
        .text-body-1-regular{
          font-size: 14px;
          line-height: 21px;
        }
      }

      .kolaborasi-detail {
        img {
          width: 100%;
          height: auto;
          min-width: none;
          max-width: none;
          background-color: #D9D9D9;
        }

        .kolaborasi-highlight {
          .text-heading-3-bold {
            font-size: 24px;
            line-height: 36px;
          }

          .text-body-1-regular {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
