.carousel.custom-carousel {
  padding-bottom: calc(2rem + 0.75rem);

  .carousel-inner {
    .carousel-item {
      iframe {
        width: 100%;
        height: auto;
        min-height: 478.78px;
      }

      iframe,
      img {
        border-radius: 8px;
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0;

    button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $neutral-10;
      transition: all 0.25s ease-in-out;
      opacity: 1;
      border-top: 0;
      border-bottom: 0;
      margin-right: 8px;
      margin-left: 8px;

      &.active {
        width: 40px;
        background-color: $secondary !important;
        border-radius: 26px;
      }
    }
  }

  &.neutral-40 {
    .carousel-indicators {
      button {
        background-color: $neutral-40;
      }
    }
  }
}
