.landing-page {

  #landing-section-1 {
    background-color: $main-color;
    color: #1E1E18;

    #section-1-top {
      display: flex;
      justify-content: space-between;
      padding: calc(1.5rem + 1.844vw) calc(2rem + 7.222vw) 0;

      #section-1-right {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 400px;
        }
      }

      #section-1-left {
        padding-top: 1.75rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        align-items: start;

        .teks-1 {
          font-weight: 500;
        }

        .teks-2 {
          padding-left: 0px !important;
        }

        .section-1-mulai {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.75rem 1rem;
          margin-top: 1.75rem;
        }

        .section-1-highlight {
          display: flex;
          gap: calc(1.5rem + 3.333vw);
          // margin-top: calc(2rem + 3.472vw);
          margin-top: 24px;

          .highlight-item {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.063rem;
            color: #272608;

            padding-left: 10px;
            border-left: 4px solid $secondary;
            white-space: nowrap;
          }
        }
      }
    }

    #section-1-bottom {
      margin-top: calc(2rem + 2.222vw);
      padding: 0 calc(2rem + 7.222vw) calc(1rem + 1.389vw);
    }

    .btn {
      background-color: #ED3237;
      color: white;
      margin: 36px;
      background-image: none;
      border-radius: 8px;
      margin-left: 0px;

    }

    @media (max-width: 425px) {
      padding: 16px;
      padding-bottom: 2rem;

      #section-1-top {
        padding: 0px;

        #section-1-left {
          align-items: center !important;

          .btn {
            margin: 16px;
            margin-bottom: 22px;
            padding: 7px 18px;
            font-size: 14px;
          }

          .teks-1 {
            color: black !important;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            text-align: center;
            margin-bottom: 0px !important;
          }

          .teks-2 {
            background-color: transparent !important;
            width: 100% !important;
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0.08px;
            color: black !important
          }

          .text-body-1-regular {
            text-align: center;
          }

          .section-1-highlight {
            margin-top: 0px;

            .highlight-item {
              // white-space: normal !important;
              font-size: 10px;
              text-wrap: balance;
            }
          }


        }

        #section-1-right {
          img {
            padding: 24px 64px;
          }
        }
      }

      #section-1-bottom {
        margin-top: 0px;
        padding: 0px;

        .custom-carousel {
          padding-bottom: 1.5rem;

          .carousel-indicators {
            button {
              width: 8px;
              height: 8px;
            }
          }

          .carousel-inner {
            .img-fluid {
              height: 60vw;
              object-fit: cover;
            }
          }
        }
      }
    }

    @media (min-width: 426px) and (max-width: 768px) {
      #section-1-bottom {

        .custom-carousel {

          .carousel-inner {
            .img-fluid {
              height: 40vw;
              object-fit: cover;
            }
          }
        }
      }
    }

    @media (min-width: 426px) and (max-width: 1000px) {
      #section-1-top {
        #section-1-right {
          display: flex;
          justify-content: center;

          img {
            padding: 48px 20px;
          }
        }

        #section-1-left {
          align-items: center;

          .section-1-highlight {
            margin-top: 0px;
          }
        }
      }
    }
  }

  #landing-section-2 {
    padding: calc(2rem + 4.583vw) calc(3rem + 14.792vw) calc(2rem + 2.431vw);

    .section-2-highlights {
      margin-top: calc(3rem + 5.556vw);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .highlight-item {
        text-align: center;
        padding-inline: 2rem;

        img {
          max-width: 123px;
          max-height: 123px;
        }
      }
    }

    @media (max-width: 425px) {
      padding: 16px;

      .text-heading-2-bold {
        padding: 16px 16px 0px 16px;
        margin-bottom: 0px !important;
        font-size: 20px;
      }

      .text-body-1-regular {
        padding: 16px;
        font-size: 14px
      }

      .section-2-highlights {
        margin-top: 0px;

        .highlight-item {
          padding: 16px 16px 0px;

          img {
            width: 100px;
          }

          .text-subheading-2-bold {
            margin: 8px !important;
            font-size: 16px;
          }

          .text-body-1-regular {
            padding: 0px 16px;
          }
        }
      }
    }

    @media (min-width: 426px) and (max-width: 768px) {
      padding: calc(1.5rem + 1.844vw) calc(2rem + 7.222vw) 0;

      .text-body-1-regular {
        font-size: 20px;
        line-height: 34px;
      }

      .section-2-highlights {
        margin-top: 48px;

        .highlight-item {
          padding: 32px;

          img {
            max-width: 200px;
            max-height: 200px;
          }

          .text-subheading-2-bold {
            font-size: 40px
          }
        }
      }
    }
  }

  #landing-section-3 {
    padding: calc(2rem + 2.431vw) calc(3rem + 16.458vw) calc(3rem + 4.097vw);

    .section-3-highlights {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5rem;

      .highlight-item {
        text-align: center;
        // padding-inline: 2rem;

        img {
          margin-bottom: 1.25rem;
          max-width: 123px;
          max-height: 123px;
        }
      }
    }

    @media (max-width: 425px) {
      padding: 37.5px;

      .text-heading-2-bold {
        font-size: 20px;
      }

      .section-3-highlights {
        margin-top: 0px;

        .highlight-item {
          padding: 0px;
          padding-bottom: 24px;

          .text-subheading-2-bold {
            font-size: 14px;
          }

          img {
            padding: 12px 21px;
            margin-bottom: 0px;
            width: 110px;
          }
        }
      }
    }

    @media (min-width: 426px) and (max-width: 768px) {
      .section-3-highlights {
        .highlight-item {
          padding-bottom: 48px;
        }
      }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      padding: 100px;
    }

  }

  #landing-section-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    position: relative;
    padding: calc(2rem + 3.056vw) calc(3rem + 5.139vw) calc(2rem + 1.389vw) calc(3rem + 6.806vw);
    gap: calc(2rem + 5.694vw);

    .btn {
      background-color: #ED3237 !important;
      color: white !important;
      background-image: none;
      border-radius: 8px;
      margin-left: 0px;
    }

    &::before {
      position: absolute;
      z-index: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      content: "";
      background: url('../../assets/bg/bg-arrow-1.svg') no-repeat calc(4rem + 9.556vw);
    }

    .section-4-left {
      z-index: 1;

      .mobile-ver {
        display: none;
      }
    }

    .desktop-ver {
      display: flex;
      justify-content: center;

      .image-wrapper {
        height: auto;
        max-width: fit-content;
        width: 39.792vw;
        overflow: hidden;
        border-radius: .25rem;
        box-shadow: 1rem 1rem $neutral-70;

        img {
          border-radius: .25rem;
        }
      }
    }

    @media (max-width: 425px) {
      padding: 16px;
      display: grid;
      justify-content: center;

      @media (max-width: 320px) {
        .text-body-1-regular {
          padding: 24px;
        }
      }

      .desktop-ver {
        display: none;
      }

      .mobile-ver {
        display: block !important;

        .image-wrapper {
          margin-bottom: 30px;
          width: 260px;
          margin-left: 40px;
          margin-right: 54.18px;
          box-shadow: 1rem 1rem $neutral-70;
          border-radius: .25rem;

          img {
            border-radius: .25rem;
          }
        }
      }

      .section-4-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .text-heading-3-bold {
          font-size: 20px;
          text-align: center;
        }

        .text-body-1-regular {
          font-size: 12px;
          text-align: center;
          margin-left: 4px;
          margin-right: 4px;
        }

        a {
          background-color: #ED3237 !important;
          color: white !important;
          background-image: none;
          border-radius: 8px;
        }
      }
    }

    @media (min-width: 426px) and (max-width: 768px) {
      display: grid;
      align-items: center;
      text-align: center;
      justify-content: center;

      .section-4-left {
        a {
          background-color: #ED3237 !important;
          color: white !important;
          background-image: none;
          border-radius: 8px;
        }
      }

      .section-4-right {
        margin-top: -52px;

        .image-wrapper {
          width: 55vw;
        }
      }
    }
  }

  #landing-section-5 {
    padding: calc(2rem + 8.125vw) calc(2rem + 7.222vw) calc(2rem + 2.778vw);

    @media (max-width: 425px) {
      padding: 16px;
      padding-bottom: 0px;
      margin-top: 34px;

      .carousel-indicators {
        padding-bottom: 18px;
      }

      .carousel-inner {
        iframe {
          min-height: 100px;
          height: 50vw
        }
      }
    }
  }

  #landing-section-6 {
    padding: calc(2rem + 2.778vw) calc(2rem + 7.222vw) calc(2rem + 2.674vw);

    .btn-outline-secondary {
      border-radius: 8px;
    }

    .section-6-description {
      padding-inline: 9.375vw;
    }

    .custom-nav-buttons {
      margin-block: 2rem;

      .nav-item {
        a {
          width: auto;
          border-radius: 8px;
        }
      }
    }

    .program-list {

      .card {
        .program-action {
          .button-daftar {
            background-color: #ED3237 !important;
            color: white !important;
            margin: 36px;
            background-image: none;
            border-radius: 8px;
            margin-left: 0px !important;
          }
        }
      }

      .program-info {
        .badge {
          background-image: none;
        }
      }
    }

    @media (max-width: 425px) {
      padding: 16px;
      padding-top: 0px;

      .text-heading-2-bold {
        font-size: 20px;
        margin-bottom: 16px !important;
      }

      .text-body-1-regular {
        font-size: 14px;
      }

      .custom-nav-buttons {
        gap: 8px;
        margin-top: 16px;

        .nav-item {
          a {
            font-size: 12px;
            width: auto;
            padding: 8px 16px;
            border-radius: 8px;
          }
        }
      }

      .program-list {
        gap: 24px;

        .program-info {
          padding-top: 8px;
          padding-bottom: 0px;

          .badge {
            padding: 8px 16px !important;
          }
        }

        .program-action {
          padding-top: 0px;

          .button-daftar {
            margin: 0px;
            font-size: 14px;
          }

          .button-selengkapnya {
            font-size: 14px;
          }
        }
      }
    }

    @media (min-width: 426px) and (max-width: 768px) {
      .section-6-description {
        padding: 0px;
      }

      .custom-nav-buttons {
        gap: 12px;
      }

      .program-list {
        gap: 36px;
      }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      padding: 48px;

      .program-list {
        .card {
          .program-info {
            .badge {
              display: grid;
              justify-content: center;
              align-items: flex-start;
            }
          }

          .program-action {
            padding-bottom: 48px;

            .button-selengkapnya {
              padding: 0px;
            }
          }
        }
      }
    }

  }

  #landing-section-7 {
    padding: calc(2rem + 2.674vw) calc(2rem + 10.799vw) calc(2rem + 6.667vw);

    .testimony-list {
      margin-top: 2rem;
      --bs-gutter-x: 2rem;

      .card {
        border: 2px solid $neutral-70;
        border-radius: 8px !important;
        padding: 2rem 1.5rem;
        box-shadow: 0.5rem 0.5rem $neutral-70;

        * {
          color: #1E1E18;
        }

        .testimony-info {
          margin-top: 4rem;
          display: flex;
          align-items: center;
          gap: 1.25rem;

          img {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;

          }
        }
      }
    }

    @media (max-width: 425px) {
      padding: 16px;

      .text-heading-2-bold {
        margin-bottom: 0px !important;
        font-size: 20px;
      }

      .section-7-description {
        font-size: 14px;
        padding: 18px;
      }

      .testimony-list {
        margin-top: 0px;

        .line-clamp-6 {
          font-size: 14px;
        }

        .col-lg-4 {
          padding: 16px 24px 8px 16px;
        }
      }
    }

    @media (min-width: 426px) and (max-width: 991px) {
      .testimony-list {
        gap: 48px;
      }
    }

    @media (min-width: 992px) and (max-width: 1350px) {
      padding: 48px;
    }
  }

  #landing-section-8 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    position: relative;
    padding: calc(2rem + 1.111vw) calc(3rem + 10.358vw) 0 calc(3rem + 6.111vw);
    gap: calc(2rem + 4.097vw);

    &::before {
      position: absolute;
      z-index: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      content: "";
      background: url('../../assets/bg/bg-arrow-1.svg') no-repeat calc(4rem + 9.556vw);
    }

    .section-8-left {
      z-index: 1;
    }

    .section-8-right {
      .image-wrapper {
        height: auto;
        width: 32.003vw;
      }
    }

    .btn {
      background-color: #ED3237 !important;
      color: white !important;
      background-image: none;
      border-radius: 8px;
      margin-left: 0px !important;
    }

    .mobile-ver {
      display: none;
    }

    @media (max-width: 425px) {
      display: grid;
      padding: 16px;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 24px;

      .desktop-ver {
        display: none !important;
      }

      .mobile-ver {
        display: block !important;
        justify-content: center;
        display: grid !important;
        margin-bottom: 16px;

        .image-wrapper {
          width: 13.5rem;
        }
      }

      .section-8-left {
        .text-heading-3-bold {
          font-size: 20px;
        }

        .text-body-1-regular {
          font-size: 14px;
          padding-right: 8px;
          padding-left: 8px;
        }
      }
    }
  }

  #landing-section-9 {
    padding: calc(2rem + 6.667vw) calc(3rem + 11.549vw) calc(2rem + 2.951vw);

    .btn-outline-secondary {
      border-radius: 8px;
    }

    .custom-carousel {
      margin-block: 2rem;

      .card.event-flyer {
        border: 1px solid $neutral-30;
        box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
        border-radius: 8px !important;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        .flyer-img {
          min-width: calc(15rem + 12.083vw);
        }

        .event-info {
          padding: 1.5rem;

          .btn {
            background-color: #ED3237;
            color: white;
            margin: 36px;
            background-image: none;
            border-radius: 8px;
            margin-left: 0px;
          }
        }
      }
    }

    @media (max-width: 700px) {
      padding: 16px;

      .text-heading-2-bold {
        font-size: 20px;
      }

      .custom-carousel {
        margin-top: 16px;

        .event-flyer {
          display: flex !important;
          flex-direction: column !important;
          justify-content: flex-end !important;
          height: 100% !important;

          .event-info {
            .text-subheading-1-bold {
              margin-bottom: 11px !important;
            }

            .text-body-2-medium {
              margin-bottom: 17px !important;
            }
          }

          .btn {
            width: 100%;
          }
        }
      }

    }

    @media (min-width: 701px) and (max-width: 768px) {
      padding: 48px;
    }
  }

  #landing-section-10 {
    padding: calc(2rem + 2.951vw) calc(3rem + 6.111vw) calc(2rem + 3.507vw);

    .btn-outline-secondary,
    .btn-outline-light {
      border-radius: 8px;
    }

    .highlight-article {
      height: calc(20rem + 17.639vw);
      width: 100%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      img {
        max-width: 100%;
        height: 100%;
        z-index: 0;
      }

      .highlight-info {
        position: absolute;
        bottom: 0;
        padding: 5.5rem 1.5rem 1rem;
        background: linear-gradient(180deg, rgba(30, 30, 24, 0) 0%, #1E1E18 74.1%);

        * {
          color: $neutral-10;
        }

        .text-body-2-regular {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .btn {
          border: 1px solid $neutral-10;

          &:hover {
            background-color: #FFFFFF10;
          }
        }
      }
    }

    .article-list {
      display: flex;
      flex-direction: column;
      // gap: 1.5rem;
      margin-bottom: 2.25rem;

      .article-item {

        padding: 1rem;
        display: flex;
        gap: 1rem;

        .img-wrapper {
          height: calc(4rem - 2px + 3.472vw);
          width: calc(4rem + 3.611vw);
          min-width: calc(4rem + 3.611vw);
          overflow: hidden;

          img {
            border-radius: 4px;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .text-body-1-bold {
          color: #1E1E18;
        }

        .text-body-2-regular {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 16px;

      .article-list {
        .article-item {
          gap: 8px;

          .img-wrapper {
            height: auto;
            padding: 8px;
            padding-right: 0px;
          }

          .article-info {
            padding: 8px;
            padding-left: 0px;

            .text-body-1-bold {
              font-size: 14px;
              line-height: 21px;
              padding-bottom: 6px;
            }

            .text-body-2-regular {
              p {
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 0.14px !important;
              }
            }
          }
        }

        .btn {
          padding: 0px;
          margin-right: auto;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-decoration-skip-ink: none;
          margin-top: 24px;
          padding-bottom: 8px;
        }
      }

      .text-center {
        font-size: 20px;
        margin-bottom: 0px !important;
      }

      .highlight-article {
        height: 340px !important;
        filter: brightness(0.9);

        .highlight-info {
          padding: 24px;

          .text-subheading-1-bold {
            font-size: 14px;
            line-height: 28px;
          }

          .text-body-2-regular {
            p {
              font-size: 12px;
              font-weight: 300;
              line-height: 18px;
              letter-spacing: 0.14px !important;
            }
          }

          .btn {
            font-size: 12px;
          }
        }
      }
    }

    @media (min-width: 710px) and (max-width: 768px) {
      padding: calc(2rem + 8.125vw) calc(2rem + 7.222vw) calc(2rem + 2.778vw);

      .highlight-article {
        height: auto !important;
      }
    }

    @media (min-width: 992px) and (max-width: 1300px) {
      padding: 48px;
    }
  }

  #landing-section-11 {
    padding: calc(2rem + 3.507vw) calc(3rem + 6.111vw) calc(2rem + 8.333vw);

    .custom-carousel {
      margin-top: 2rem;

      img {
        border: 1px solid $neutral-30;
        border-radius: 12px;
      }
    }

    @media (max-width: 425px) {
      padding: 0px 16px 16px;

      .text-heading-2-bold {
        font-size: 20px;
      }

      .custom-carousel {
        margin-top: 0px;
        padding-bottom: 24px;

        .carousel-indicators {
          button {
            width: 9px;
            height: 9px;
          }
        }

        .carousel-inner {
          .carousel-item {
            img {
              max-width: 100%;
              height: 340px;
              filter: brightness(0.5);
              object-fit: cover;
            }

            .img-textbox {
              padding: 24px;

              .textbox {
                h3 {
                  font-size: 14px;
                }

                .text-description {
                  display: none !important
                }
              }
            }
          }
        }
      }
    }
  }

  #landing-section-12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    position: relative;
    padding: calc(2rem + 4.931vw) calc(3rem + 9.028vw) calc(2rem + 2.778vw) calc(3rem + 9.236vw);
    gap: calc(2rem + 7.361vw);

    &::before {
      position: absolute;
      z-index: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      content: "";
      background: url('../../assets/bg/bg-arrow-1.svg') no-repeat calc(4rem + 9.556vw);
    }

    .section-12-left {
      z-index: 1;
    }

    .section-12-right {
      .image-wrapper {
        height: auto;
        width: 32.003vw;
      }
    }

    .contact-us {
      * {
        letter-spacing: 0px !important;
      }
    }

    .btn {
      background-color: #ED3237 !important;
      color: white !important;
      background-image: none;
      border-radius: 8px;
      margin-left: 0px !important;
    }

    .mobile-ver {
      display: none;
    }

    @media (max-width: 425px) {
      display: grid;
      padding: 40px;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 24px;

      .desktop-ver {
        display: none !important;
      }

      .mobile-ver {
        display: block !important;
        justify-content: center;
        display: grid !important;
        margin-bottom: 16px;

        .image-wrapper {
          width: 13.5rem;

          .img-fluid {
            max-width: 80%;
          }
        }
      }

      .section-12-left {
        .text-heading-3-bold {
          font-size: 20px;
        }

        .text-body-1-regular {
          font-size: 14px;
          padding-right: 8px;
          padding-left: 8px;
        }
      }

    }
  }
}