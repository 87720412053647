#event-page {
  position: relative;
  padding: 2.5rem calc(3rem + 6.111vw) calc(2rem + 1.806vw);

  .card {
    border: 1px solid $neutral-40;
    border-radius: 12px !important;
    box-shadow: none !important;

    .card-body {
      padding: 1.5rem 3.25rem;

      .card {
        border: 1px solid $neutral-30;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1) !important;
        border-radius: 8px !important;

        .card-body {
          padding: 1rem;
          cursor: pointer;

          img {
            object-fit: contain;
            min-height: 200px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 2.5rem 1.111vw calc(1rem + 1.389vw);
    .card{
      .card-body{
        padding: 0.75rem 0.50rem;
      }
    }
  }
}

#event-detail-page {
  position: relative;
  padding: 2.5rem calc(3rem + 6.597vw);

  .card {
    border: 1px solid $neutral-30;
    border-radius: 1rem;
    box-shadow: none;

    .card-body {
      padding: 2rem 1.5rem 1.5rem;

      .event-detail-info {
        --bs-gutter-x: 2.5rem;
        margin-bottom: 2.5rem;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .form-control,
      .form-select {
        padding: 0.75rem 1rem;
        font-family: $font-family-rubik !important;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.313;

        letter-spacing: 0.0115em;

        background-color: $neutral-10 !important;
        border: 1px solid $neutral-30 !important;
        border-radius: 8px;
      }

    }
  }
  @media (max-width: 768px) {
    padding: 2.5rem 1.111vw calc(1rem + 1.389vw);
    .card{
      .card-body{
        padding: 1rem;
      }
    }
  }
}
