@import './_variables';
@import 'base';

html {
  font-size: 16px;

  body {
    background-color: $neutral-10;
  }
}

* {
	font-family: $font-family-montserrat !important;

}

body{
  overflow-x: hidden;
}

pre, code, kbd, samp {
  font-family: $font-family-monospace !important;
}

@import 'app';
@import 'react';
@import 'components';
@import 'custom-components';
@import 'typography';
@import 'layout';
@import 'pages';
@import 'utils';
