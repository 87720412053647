.custom-pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  li {
    display: flex;

    a {
      font-family: $font-family-rubik !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0115em;

      color: #1E1E18 !important;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      min-height: 40px;
      min-width: 40px;
    }

    &:not(.selected, .disabled):hover {
      a {
        background-color: #00000010;
      }
    }

    &.selected {
      a {
        background-color: $secondary;
        color: $neutral-10 !important;
      }
    }

    &.disabled {
      a {
        opacity: .3;
        cursor: unset;
      }
    }
  }
}
