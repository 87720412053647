.uppy-Root {
	border-color: $primary;

	.uppy-DragDrop-arrow {
		fill: $primary;
	}

	.uppy-DragDrop-browse {
		color: $primary;
	}
}
