.btn {
  font-family: $font-family-rubik !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0px;

  &:hover {
    box-shadow: none !important;
  }

  &.btn-primary {
    &:hover,
    &:active {
      background-color: $primary-hover !important;
    }
  }

  &.btn-secondary {
    &:hover,
    &:active {
      background-color: $secondary-hover !important;
    }
  }
}
