#article-page {
  background-color: #fafafa;
  position: relative;
  padding: 2.5rem calc(2rem + 4.744vw) calc(2rem + 1.806vw);

  .card {
    border: 1px solid $neutral-30;
    border-radius: 12px !important;
    box-shadow: none !important;

    .card-body {
      padding: 24px calc(1.5rem + 1.186vw);

      .new-article-list {
        .card {
          padding: 1rem;
          height: 293px;
          border: none;

          .card-body {
            padding: 0;

            img {
              width: 259px;
              height: 259px;
              border-radius: 9.25px;
              object-fit: cover;
            }

            .desc-main-article {

              p,
              span,
              em {
                color: $neutral-70;
                font-weight: 400;
                font-family: $font-family-rubik !important;
                letter-spacing: 0.0115em !important;
                font-size: 14px;
                line-height: 21px;
              }

              p {
                &:not(:first-child) {
                  display: none;
                }

                // &:first-child {
                //   white-space: nowrap;
                //   text-overflow: ellipsis;
                //   overflow: hidden;
                // }
              }
            }

            .new-article-text {
              position: relative;

              >div {
                margin-bottom: 8px;
              }

              .see-more-button {
                cursor: pointer;
                position: absolute;
                right: 0;
                bottom: 0;
                color: $secondary;
              }
            }
          }
        }
      }

      .sub-article-list {
        >.row {
          padding: 1rem;
        }

        .card {
          height: 250px;
          border: none;

          .card-body {
            padding: 0;

            img {
              width: 100%;
              height: 154px;
              border-radius: 4px;
              object-fit: cover;
            }

            .title-link {
              cursor: pointer;
              color: #1e1e18;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 16px;

    .card {
      height: auto;

      .card-body {
        padding: 8px;

        .text-subheading-1-bold {
          margin-top: 18px;
        }

        .new-article-list {

          .new-article-text {
            padding-left: 0px;
          }

          .carousel-inner {
            .card-body {
              .col-6 {
                margin: 12px 0px;
              }
            }
          }

          .card {
            height: auto;
          }
        }

        .img-fluid {
          height: 160px !important;
        }
      }

    }

  }
}

#article-detail-page {
  background-color: #fafafa;
  position: relative;
  padding: 2.5rem calc(3.5rem + 12.973vw) calc(2rem + 1.806vw);

  .other-article {
    .row {
      .col {
        .card-body {
          .row {
            .col-sm-3 {
              padding-left: 2px;
            }
          }
        }
      }
    }
  }

  .card {
    padding: 1rem calc(1rem + 2.15vw);
    border-radius: 8px !important;
    box-shadow: none !important;

    .card-body {
      padding: 0;

      .img-article {
        width: 100%;
        height: auto;
        border-radius: 8px;
        object-fit: contain;
      }

      .desc-article {

        p,
        span,
        em {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          font-family: $font-family-rubik !important;
          letter-spacing: 0.0115em !important;
          color: #0a0a0a;
        }
      }

      .other-article {
        img {
          width: 72px;
          height: 72px;
          border-radius: 4px;
          object-fit: cover;
        }

        .title-link {
          cursor: pointer;
          color: #1e1e18;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 16px;

    .card {
      padding: 16px;

      .card-body {

        .other-article {
          img {
            width: 100%;
            height: 100%;
          }

          .row-cols-md-2 {
            margin-left: 1rem;
          }

          .card {
            .card-body {
              .row {
                height: 90px;

                .col-sm-9 {
                  padding-left: 0px;
                  padding-right: 2rem !important;

                  .title-link {
                    font-size: 14px;
                  }
                }
              }
            }
          }

        }

        .text-subheading-1-bold {
          font-size: 20px;
          margin-bottom: 1rem !important;
        }

        .desc-article {
          margin-bottom: 12px !important;

          p,
          em,
          span {
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
          }
        }

        .text-heading-3-bold {
          font-size: 20px;
          line-height: 36px;
          margin-bottom: 0px !important;
          padding-bottom: 16px;
        }

        .text-body-1-regular {
          color: #9E9E9E;
          font-size: 10px;
          font-weight: 500;
          line-height: 18px;
          word-wrap: break-word;
          margin-bottom: 12px !important;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 48px;


    .other-article {
      .row-cols-md-2 {
        margin-left: 1rem;

        .row {
          .col-sm-3 {
            padding-right: 0px;
          }
        }
      }
    }
  }
}