footer.footer {
  padding: 0;
  background-color: $neutral-10;

  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.75rem calc(1rem + 8.333vw) 1.375rem calc(1rem + 8.958vw);
    border-bottom: 2px solid #D9D9D9;
    border-top: 2px solid #D9D9D9;

    .footer-socmed {
      display: flex;
      align-items: center;
      gap: 3rem;

      .socmed-icon {
        height: 43px;
        width: 43px;
        display: flex;
      }
    }
  }

  .footer-links {
    padding: 2.875rem calc(1rem + 8.333vw) 0rem;
    display: flex;
    justify-content: space-between;

    .footer-title {
      font-family: $font-family-merriweather !important;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.015em;
    }

    .link-section {
      max-width: 19.097vw;

      .nav {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .nav-item {
          .nav-link {
            padding: 0;
            margin: 0;
            color: $neutral-80 !important;
          }
        }
      }
    }
  }

  .footer-legal {
    padding: 0rem calc(1rem + 8.333vw) 0rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    .footer-top {
      padding: 1rem 9.931vw;
      .footer-socmed {
        display: flex;
        align-items: center;
        gap: 1rem;

        .socmed-icon {
          height: auto;
          width: 100%;
          display: flex;
        }
      }
    }
    .footer-links {
      padding: 1rem 10.625vw;

      .link-section {
        max-width: none;
        margin-bottom: 1.5rem;
      }
    }
    .footer-legal {
      text-align: center;
    }
  }
}
