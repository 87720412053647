.w-fit {
  width: fit-content !important;
}

.ff-merriweather {
  font-family: $font-family-merriweather !important;
}

.img-textbox {
  height: 50%;
  width: 100%;
  background: linear-gradient(180deg, rgba(30, 30, 24, 0) 0%, #1E1E18 69.06%);
  position: absolute;
  bottom: 0;
  padding: 0 3rem 2.125rem;
  border-radius: 0 0 12px 12px;

  display: flex;
  align-items: end;

  * {
    color: white;
  }
}

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }

  .border-#{$name} {
    border-color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }
}

// LINE CLAMP
@each $size in $line-clamps {
  .line-clamp-#{$size} {
    display: inline;
    overflow: hidden;
    -webkit-box-orient: vertical;

    @supports (-webkit-line-clamp: $size) {
      display: -webkit-box;
      -webkit-line-clamp: $size;
    }
  }
}