#program-page {
  position: relative;
  padding: 2.5rem calc(3rem + 6.181vw) calc(2rem + 1.389vw);
  background-color: #FAFAFA;
  border-bottom: 1px solid #E0E0E0;

  .card {
    border: 1px solid $neutral-30;
    border-radius: 1rem !important;
    box-shadow: none;

    .program-header {
      display: flex;
      align-items: center;
      margin-bottom: 2.875rem;

      img {
        width: 100%;
        height: auto
      }
    }

    .card {
      border-radius: 0.5rem !important;
    }

    .form-control {
      background-color: $neutral-10 !important;
      border: 1px solid $neutral-40 !important;
      border-radius: 8px;
      font-family: 'Rubik';
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;

      letter-spacing: 0.0115em;

      &:disabled {
        background-color: $neutral-20 !important;
        color: $neutral-60 !important;
      }
    }

    .carousel {
      margin-block: 2.875rem;

      .carousel-item {
        img {
          display: block;
          width: 90%;
          height: 18.75rem;
          margin-inline: auto;
          background-color: $secondary;
        }

        .carousel-caption {
          top: 30%;
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        width: 5%;
        background: none;

        >span:first-of-type {
          border: 1px solid $neutral-90;
          border-radius: 4px;
          background-size: 24px;
          height: 40px;
          width: 40px;
        }
      }

      .carousel-control-prev {
        justify-content: start;
      }

      .carousel-control-next {
        justify-content: end;
      }
    }

    .detail-header {
      padding: 2.5rem;
      display: flex;
      align-items: start;
      border-bottom: 1px solid $neutral-30;

      img {
        // width: 100%;
        // height: auto;
        // object-fit: cover;
        border-radius: 8px;
        min-width: calc(12rem + 8.194vw);
        max-width: calc(12rem + 8.194vw);
        height: calc(6.625rem + 8.194vw);
        object-fit: cover;
      }
      @media (max-width: 768px) {
        padding: 1rem;
        img {
          border-radius: 8px;
          width: 100%;
          height: 13.875rem;
          object-fit: cover;
          min-width: none;
          max-width: none;
        }

      }
    }


    .profil-mentor {
      margin-bottom: 2rem;

      >.card-body {

        img {
          height: calc(4rem + 2.5vw);
          width: calc(4rem + 2.5vw);
          border-radius: 50%;
        }
      }
    }

    .card.with-shadow {
      box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 10%) !important;
    }

  }

  @media (max-width: 1024px) {
    padding: 2.5rem 1.111vw calc(1rem + 1.389vw);

    .btn {
      border-radius: 8px;
    }

    .carousel-control-next {
      display: none;
    }

    .carousel-control-prev {
      display: none;
    }
  }
}


.program-list {
  margin-bottom: 2.813rem;

  .card {
    border: 1px solid $neutral-30;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    overflow: hidden;
    height: 100%;

    img {
      width: 100%;
      height: auto;
      max-height: calc(10rem + 2.639vw);
      min-height: calc(10rem + 2.639vw);
      object-fit: cover;
    }

    .badge {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.0115em !important;
    }

    .program-info {
      padding: 1rem;
      border-bottom: 1px solid $neutral-20;
      height: 100%;
    }

    .program-action {
      padding: 1rem;
    }
  }
}
